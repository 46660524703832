.shipping-details {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    font-size: 1rem;
    white-space: nowrap;

    @media screen and (max-width: 768px) {
        font-size: 0.6rem;
    }
}

.input-box{
    all: unset;
    background: white;
    color: black;
    border-radius: 7px;
    padding: 5px;
}

.not-specified{
    color: rgb(128, 128, 128);
}

.inside-input-button{
    display: inline-block;
    position: relative;
    left: -25px;
    background: white;
    padding: 0;
    border: none;
}