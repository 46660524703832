.sizes-inputs {
    width: 17vw;
    display: flex;
    flex-direction: row;
    column-gap: 7px;
    justify-content: start;
    align-items: start;
}

.sizes-input-container {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    row-gap: 10px;

}

.add-size-but{
    background-color: slategray;
    padding: 4px;
    font-size: 1rem;
    cursor: pointer;
}