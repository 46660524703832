
.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.login-form {
    width: 400px;

    @media screen and (max-width: 728px) {
        width: 300px;
    }
}