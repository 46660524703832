
.products-container {
    display: flex;
    flex: 1 1 auto;
    column-gap: 10px;
    row-gap: 10px;
    flex-direction: column;
}

.product-container {
    background: #f1c40f;
    padding: 10px;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    color: #001529;

    @media screen and (max-width: 768px) {
        font-size: 0.6rem;
    }
}