/* .add-new {
    padding: 4px;
    font-size: large;
    margin-left: 6vw;
    background-color: rgb(255, 187, 0);
    color: black;
    font-weight: bolder;
    border-radius: 4px;
    position: absolute;
    right: 10px;
    cursor: pointer;
}    */

.custom-edits {
    width: 14vw;
    column-gap: 10px;
    white-space: nowrap;
    font-weight: bolder;
}

.add-new-button {
    position: absolute;
    right: 10px;
}