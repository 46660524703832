

.upload-button {
    background-color: #001529;
    color: transparent;
    padding: 10px;
    border-radius: 8px;
}

.upload-button::-webkit-file-upload-button{
    background-color: transparent;
    border: 1px solid white;
    color: #f1c40f;
    padding: 6px;
    border-radius: 6px;
}

.upload-button::-webkit-file-upload-button:hover {
    cursor:pointer;
}

.images-preview-container {
    border: 3px solid #828599;
    border-radius: 7px;
    padding: 5px;
}

.no-images-text {
    color: #001529;
    font-weight: bold;
    font-size: 20px;
    padding: 20px;
}