
.order-container {
    margin-top: 50px;
    display: flex;
    flex: 1 1 auto;
    flex-wrap: wrap;
    column-gap: 50px;
    row-gap: 50px;
}
.wrapper {
    display: flex;
    flex: 1 1 auto;
    flex-wrap: wrap;
    justify-content: start;
    align-items: start;
    flex-direction: column;
}
.delivery-status-container{
    display: flex;
    align-items: start;
    justify-content: start;
    flex-direction: row;
    flex-wrap: wrap;
}

.delivery-status{
    font-weight: 600;
}

.delivery-status-select{
    margin-left: 10px;
    font-size: 1rem;
}

.header{
    display: flex;
    justify-content: space-between;
    align-items: start;
    box-sizing: border-box;
}

.save-button{
    all: unset;
    background: #001529;
    padding: 20px 25px;
    border-radius: 15px;
    color: #f1c40f;
    font-weight: bold;

    @media screen and (max-width: 728px) {
        padding: 10px 15px;
    }
}