body {
  font-family: Roboto, sans-serif;
}

.trigger {
  padding: 0 24px;
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;
}
.card {
  display: flex;
  background: #001529;
  color: white;
  width: 400px;
  padding: 1rem;
  border: 2px solid transparent;
  box-shadow: #212529 1px 0 0 1px;

  @media screen and (max-width: 768px) {
    width: 200px;
  }
}

.mr-10 {
  margin-right: 10px;
}

.color-black {
  color: black;
}

.trigger:hover {
  color: #1890ff;
}

.logo {
  height: 32px;
  margin: 16px;
  background: rgba(255, 255, 255, 0.3);
}
.ant-layout {
  min-height: 100vh;
}
.ant-layout-sider {
  flex: 0 0 250px !important;
  max-width: 250px !important;
  min-width: 250px !important;
  width: 250px !important;
}
.ant-layout-sider-collapsed {
  flex: 0 0 80px !important;
  max-width: 80px !important;
  min-width: 80px !important;
  width: 80px !important;
}
.sm-logo {
  display: none;
}

.ant-layout-sider-collapsed .sm-logo {
  display: block;
}
.ant-layout-sider-collapsed .lg-logo {
  display: none;
}
.logo {
  background-color: #ffd333;
  margin: 0;
  height: 64px;
}
main {
  height: 85vh;
  overflow-y: scroll;
  background-color: transparent !important;
}
main::-webkit-scrollbar {
  width: 0;
}
.ant-menu-title-content {
  font-size: 16px;
}
.ant-layout-header h5 {
  line-height: 20px;
  font-size: 14px;
}
.ant-layout-header p {
  line-height: 20px;
  font-size: 13px;
}
.ant-layout-header div .badge {
  top: 16px;
  right: -6px;
}
.error {
  color: red;
  font-size: 12px;
}

.font-header {
  font-size: 2rem;
}

.font-info {
  font-size: 1rem;
}

.font-sizes {
  font-size: 1.6rem;
}

.whitespace-nowrap {
  white-space: nowrap;
}

.ml-20 {
  margin-left: 20px;
}

.link {
  color: #1890ff;
  text-decoration: underline;
  border: none;
  background: none;
}

.text-light-blue {
  color: #1890ff;
}

.gap-30 {
  column-gap: 30px;
  row-gap: 30px;
}
.pointer {
  cursor: pointer;
}
.col-gap-20 {
  column-gap: 20px;
}
.delete-button {
  width: 24px;
  height: 24px;
  color: red;
  position: absolute;
  right: 15px;
  top: 10px;
  background: none;
  border: none;
}

textarea{
  resize: none;
}
.h-30-vh {
  height: 30vh;
}
.ml-7 {
  margin-left: 7px;
}
.add-new {
    padding: 4px;
    font-size: large;
    white-space: nowrap;
    max-width: max-content;
    background-color: rgb(255, 187, 0);
    color: black;
    font-weight: bolder;
    border-radius: 4px;
    cursor: pointer;
}

.nowrap {
  white-space: nowrap;
}
