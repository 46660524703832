

.product-inf-container {
    display: flex;
    flex: 1 1 auto;
    justify-content: start;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    column-gap: 20px;
    row-gap: 20px;
}

.main-product-view-container {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    row-gap: 30px;
    column-gap: 30px;
    flex: 1 1 auto;
    justify-content: start;
}

.product-information-container {
    margin-top: 20px;
}

.card-title {
    text-transform: capitalize;
    margin-bottom: 15px;

}

.sale-select {
    margin-left: 0.6rem;
}